import { useCallback, useEffect, useState } from 'react'
import { Selector, useDispatch, useSelector } from 'react-redux'
import { useIsomorphicLayoutEffect } from 'usehooks-ts'

import { ServiceValues } from '../helpers/ReduxHelpers'

export const useServiceRequest = <Data, Params>(
  selector: Selector<any, ServiceValues<Data, Params>>,
  action: any,
  actionReset?: any,
  onComplete?: (s: ServiceValues<Data, Params>) => void | null
): [ServiceValues<Data, Params>, (values?: Params) => void, () => void] => {
  const dispatch = useDispatch()
  const [isPending, setIsPending] = useState(false)
  const submit: ServiceValues<Data, Params> = useSelector(selector)

  const handleSubmit = useCallback(
    (values?: Params) => {
      setIsPending(true)
      dispatch?.(action(values))
    },
    [dispatch, action]
  )

  const handleReset = useCallback(() => {
    dispatch?.(actionReset())
  }, [dispatch, actionReset])

  useIsomorphicLayoutEffect(() => {
    if (submit.success && isPending) {
      onComplete?.(submit)
    }
  }, [dispatch, isPending, onComplete, submit])

  useEffect(() => {
    return () => {
      if (actionReset) {
        dispatch?.(actionReset())
      }
    }
  }, [dispatch, actionReset])

  return [submit, handleSubmit, handleReset]
}
